import React from 'react';

import '../../css/App.module.css';

class About extends React.Component {
	render() {
		return (
			<div className="mainContainer centeredVertically">
				<h1>BIO</h1>

				<p style={{ paddingTop: "1rem", paddingBottom: "1rem" }}>
					<b>Lawrence Luo</b> is a composer, arranger, and multi-instrumentalist based in San Francisco with nearly two decades of experience creating and performing music.
					He has garnered awards for his contemporary compositions and arrangements, and his work for chamber ensembles, jazz-influenced bands, and a cappella groups are performed regularly in-concert.
				</p>

				<div id="performed-with">
					<p style={{ paddingBottom: "0.5rem" }}>Lawrence has performed with:</p>

					<ul>
						<li><a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/watch?v=Jc86LBy_gws&list=PLZ8xVCciQ1ohq810z6AmckrDMgbjwDR2i&index=1&ab_channel=Lawrence%2F%2FTonic">RL Piano & Vocal Duo</a></li>
						<li><a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/theotherreindeercarolers/">The Other Reindeer Carolers</a></li>
						<li><a target="_blank" rel="noopener noreferrer" href="https://open.spotify.com/artist/0HVbw8ntLBVDL9fpJYMljZ?si=FtekFl36RtuUTf78rNBbBA">Alex Togashii</a></li>
						<li><a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/QuarterAfterBand/">Quarter After Band</a></li>
						<li><a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/channel/UCUmsLPXkFTBv5TpdUuedSdw">The A Cappella Collective</a></li>
						<li><a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/user/buinachord2">Boston University's In Achord</a></li>
					</ul>
				</div>
			</div>
		)
	}
}

export default About;