import React from 'react';
import useMediaQuery from '../hooks/useMediaQuery';
import { analytics } from '../firebase';
import { logEvent } from 'firebase/analytics';

import '../css/App.module.css';
import styles from '../css/Header.module.css';

const handleNavClick = (linkType) => {
  if (analytics) {
    logEvent(analytics, 'nav_link_click', {
      link_type: linkType
    });
  }
};

const TechHeader = () => {
  return (
    <div className={styles.headerContainer}>
      <div id="nav">
        <span className="float-left">
          <a href="/" onClick={() => handleNavClick('home')}>HOME</a>
        </span>
        <span className="float-right">
          {/* <a className={styles.navLink} href="/work" onClick={() => handleNavClick('work')}>WORK</a> */}
          <a className={styles.navLink} href="/teaching" onClick={() => handleNavClick('teaching')}>TEACHING</a>
        </span>
      </div>
    </div>
  )
}

const MusicHeader = () => {
  const [showMenu, setShowMenu] = React.useState(false);
  const isMobile = useMediaQuery("(max-width: 800px)");

  const handleMenuToggle = (isOpening) => {
    if (analytics) {
      logEvent(analytics, 'mobile_menu_toggle', {
        action: isOpening ? 'open' : 'close'
      });
    }
    setShowMenu(isOpening);
  };

  if (!isMobile) return (
    <div id="nav" className={styles.headerContainer}>
      <span className="float-left">
        <a className={styles.navLink} href="/" onClick={() => handleNavClick('home')}>HOME</a>
      </span>

      <span className="float-right">
        <a className={styles.navLink} href="/about" onClick={() => handleNavClick('about')}>ABOUT</a>
        <a className={styles.navLink} href="/commissions" onClick={() => handleNavClick('commissions')}>COMMISSION</a>
        <a className={styles.navLink} href="/work" onClick={() => handleNavClick('work')}>WORK</a>
      </span>
    </div>
  )

  return (
    !showMenu
    ?
    <div id="nav" className={styles.headerContainer}>
      <span className="float-left">
        <a href="/" onClick={() => handleNavClick('home')}>HOME</a>
      </span>

      <span className="float-right">
        <i
          className="icon fa fa-bars"
          onClick={() => handleMenuToggle(true)}
        />
      </span>
    </div>
    :
    <div id="nav" className={`${styles.fullScreenModal}`}>
      <span className="float-right">
        <i
          className="icon fa fa-close"
          onClick={() => handleMenuToggle(false)}
        />
      </span>

      <div className={styles.modalBodyContainer}>
        <a className={styles.navLink} href="/about" onClick={() => handleNavClick('about')}>ABOUT</a>
        <div>
          <a className={styles.navLink} href="/commissions" onClick={() => handleNavClick('commissions')}>
            REQUEST A COMMISSION
          </a>
        </div>
        <div>
          <a className={styles.navLink} href="/work" onClick={() => handleNavClick('work')}>
            BROWSE WORK
          </a>
        </div>
        <div>
          <a className={styles.navLink} href="/contact" onClick={() => handleNavClick('work')}>
            CONTACT ME
          </a>
        </div>
      </div>
    </div>
  )
}

const Header = () => {
  return process.env.REACT_APP_WEBSITE_TYPE === "MUSIC" ? <MusicHeader /> : <TechHeader />
}

export default Header;