import React from 'react';

import HighlightButton from '../HighlightButton/HighlightButton';

import '../../css/App.module.css';

export default function Commissions() {
	const openForm = (url) => {
		window.open(url, "_blank", "noopener,noreferrer")
	}

	return (
		<div className="mainContainer centeredVertically">
			<h1>COMMISSIONS</h1>

			<section style={{ paddingTop: "1rem", paddingBottom: "2rem" }}>
				<p>A finished commission from me includes:</p>

				<ul>
					<li>A .pdf file of the completed full score</li>
					<li>A .wav and .mp3 file of the score's digital playback</li>
					<li>Expertly notated guidelines for phrasing, breaths, articulations, and dynamics</li>
				</ul>
			</section>


			<div className="buttonContainer">
				<HighlightButton title={"REQUEST COMMISSION"} onClick={() => openForm("https://forms.gle/WPq1pP426AdhPrjt6")} />
			</div>

			{/* <p>If you're not sure about commissioning a request due to the length of the piece or number of instruments, please send me a message first and we can work out a quote. I'm excited to work with you to create something great!</p> */}
		</div>
	);
}