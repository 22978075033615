import React from 'react';
import { IoIosClose } from "react-icons/io";
import { analytics } from '../../firebase';
import { logEvent } from 'firebase/analytics';

import '../../css/App.module.css';
import styles from './ImageOverlay.module.css';

export const ImageFrame = ({ children }) => {
  return (
    <div className={styles.imageContainer}>
      {children}
    </div>
  )
}

export const ImageOverlay = ({ src, alt, arrangement }) => {
  const [isImageLoaded, setImageLoaded] = React.useState(false);
  const [flipped, setFlipped] = React.useState(false);

  const pauseAllAudio = () => {
    let audioPlayers = document.querySelectorAll('audio');
    for (let i = 0; i < audioPlayers.length; i++) {
      audioPlayers[i]?.pause();
    }
  }

  const handleButtonClick = (buttonType) => {
    if (analytics) {
      logEvent(analytics, 'arrangement_button_click', {
        arrangement_title: arrangement.Title,
        button_type: buttonType
      });
    }
    pauseAllAudio();
  };

  const handleAudioPlay = () => {
    if (analytics) {
      logEvent(analytics, 'arrangement_audio_play', {
        arrangement_title: arrangement.Title
      });
    }
  };

  return (
    <ImageFrame>
      <div
        style={{ visibility: isImageLoaded ? 'visible' : 'hidden', cursor: 'pointer' }}
        onClick={() => {
          // Only way to unflip is to press the exit button
          if (flipped)
            return;

          pauseAllAudio();
          setFlipped(!flipped);
        }}
      >
        <img
          src={src}
          alt={alt}
          onLoad={() => setImageLoaded(true)}
          onError={() => console.log(`Failed to load image ${alt}`)}
        />

        {arrangement.SpecialText &&
        <div className={styles.topOverlay}>
          <div>{arrangement.SpecialText}</div>
        </div>
        }

        <div className={styles.middleOverlay}>
          {/* <div><FaCirclePlay className="play-icon" color="white" size="3rem" /></div> */}
          <i className={`fa fa-play-circle ${styles.playIcon}`} />
        </div>


        <div className={styles.bottomOverlay}>
          <h3 className={styles.songTitle}>{arrangement.Title}</h3>
          <div><span className={styles.subscriptText}>by </span><em className={styles.partsText}>{arrangement.By}</em></div>
          <div className={styles.partsText}>{arrangement.Parts}</div>
        </div>

        {flipped &&
        <div className={styles.overlay}>
          <div className={styles.exitContainer} onClick={() => setFlipped(false)}>
            <IoIosClose className={styles.exitIcon} size="2rem" />
          </div>

          {/* <p className={styles.arrangementDesc}>Genres: {arrangement.Genres}</p>
          <p className={styles.arrangementDesc}>Level: {arrangement.Level}</p> */}

          {arrangement.mp3 &&
          <div className={styles.htmlAudioContainer}>
            <audio
              className={styles.audio}
              controls
              autoPlay
              controlsList="nodownload noplaybackrate nomute notimeremaining"
              onPlay={handleAudioPlay}
            >
              <source src={`${process.env.PUBLIC_URL}/static/audio/${arrangement.mp3}`} type="audio/mp3" />
            </audio>
          </div>
          }

            {/*

            <Spotify link={arrangement.Spotify} />

            <div className={styles.iconContainer}>
              <a href={arrangement.Youtube} target="_blank" rel="noopener noreferrer"><i className="fa fa-youtube icon" aria-label="Youtube Link"/></a><a href={arrangement.Spotify} target="_blank" rel="noopener noreferrer"><i className="fa fa-spotify icon" aria-label="Spotify Link"/></a>
            </div> */}
            <div className={styles.mainContent}>
              <a style={{ marginRight: 10 }} href={arrangement.Gumroad} target="_blank" rel="noopener noreferrer">
                <button className={styles.actionButton} onClick={() => handleButtonClick('more_info')}>
                  <span className={styles.buttonText}>MORE INFO</span>
                </button>
              </a>

              <a href={arrangement.Gumroad} target="_blank" rel="noopener noreferrer">
                <button className={styles.actionButton} onClick={() => handleButtonClick('purchase')}>
                  <span className={styles.buttonText}>PURCHASE</span>
                </button>
              </a>
            </div>
          </div>
        }

        {/* <div className={styles.overlay}>
            <div className={styles.iconContainer}>
              <a href={arrangement.Youtube} target="_blank" rel="noopener noreferrer"><i className="fa fa-youtube icon" aria-label="Youtube Link"/></a><a href={arrangement.Spotify} target="_blank" rel="noopener noreferrer"><i className="fa fa-spotify icon" aria-label="Spotify Link"/></a>
            </div>

            <h1>{arrangement.Title}</h1>
            <h2 className={styles.opb}>{arrangement.By}</h2>
            <div>{arrangement.Parts}</div>
            <p><a href={arrangement.Gumroad} target="_blank" rel="noopener noreferrer">Purchase</a></p>
          </div> */}
      </div>
    </ImageFrame>
  );
};