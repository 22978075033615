import React from 'react';
import { Routes, Route } from 'react-router-dom';

import { Home, Music, About, Teaching, LessonPolicy, Work, Resume, Arrangements, Commissions, Contact, Page404 } from './Pages';

// Docs: https://github.com/remix-run/react-router/blob/main/docs/getting-started/tutorial.md
// NavLink - allows styling links conditionally based on current Route

const MusicNavigator = () => {
  return (
    <Routes>
      <Route path="/" element={<Music />} />
      <Route path="about" element={<About />} />
      <Route path="work" element={<Arrangements />} />
      <Route path="commissions" element={<Commissions />} />
      <Route path="contact" element={<Contact />} />
      <Route path="*" element={<Page404 />} />
    </Routes>
  )
}

const TechNavigator = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="teaching" element={<Teaching />} />
      <Route path="lessonPolicy" element={<LessonPolicy />} />
      <Route path="work" element={<Work />} />
      <Route path="resume" element={<Resume />} />
      <Route path="contact" element={<Contact />} />
      <Route path="*" element={<Page404 />} />
    </Routes>
  )
}

const Main = () => {
  return process.env.REACT_APP_WEBSITE_TYPE === "MUSIC" ? <MusicNavigator /> : <TechNavigator />
}

export default Main;
