import React from 'react';
import { analytics } from '../firebase';
import { logEvent } from 'firebase/analytics';

import '../css/App.module.css';
import styles from '../css/Footer.module.css';

const handleLinkClick = (linkType) => {
  if (analytics) {
    logEvent(analytics, 'footer_link_click', {
      link_type: linkType
    });
  }
};

const TechFooter = () => {
  return (
    <div className={styles.footerContainer}>
      <div className={styles.socialLinks}>
        {/* <a href="mailto:luolawrence1@gmail.com" onClick={() => handleLinkClick('email')}>
          <i className="fa fa-envelope-square" aria-hidden="true" />
          <span className={styles.srOnly}>Email</span>
        </a> */}
        <a href="/contact" onClick={() => handleLinkClick('contact_form')}>
          <i className="fa fa-envelope-square" aria-hidden="true" />
          <span className={styles.srOnly}>Contact</span>
        </a>
        <a href="https://github.com/lawrluor" rel="noopener noreferrer" target="_blank" onClick={() => handleLinkClick('github')}>
          <i className="fa fa-github-square" aria-hidden="true" />
          <span className={styles.srOnly}>Github</span>
        </a>
        <a href="https://www.instagram.com/notesfromlaw" rel="noopener noreferrer" target="_blank" onClick={() => handleLinkClick('instagram')}>
          <i className="fa fa-instagram" aria-hidden="true" />
          <span className={styles.srOnly}>Instagram</span>
        </a>
        {/* <a href="https://ko-fi.com/lawrenceluo" rel="noopener noreferrer" target="_blank"><i className="fa fa-gift" aria-hidden="true" /><span className="sr-only">Donate</span></a> */}
      </div>
    </div>
  )
}

const MusicFooter = () => {
  return (
    <div className={styles.footerContainer}>
      <div className={styles.socialLinks}>
        {/* <a href="mailto:luolawrence1@gmail.com" onClick={() => handleLinkClick('email')}>
          <i className="fa fa-envelope-square" aria-hidden="true" />
          <span className={styles.srOnly}>Email</span>
        </a> */}
        <a href="/contact" onClick={() => handleLinkClick('contact_form')}>
          <i className="icon fa fa-envelope-square" aria-hidden="true" />
          <span className={styles.srOnly}>Contact</span>
        </a>
        <a href="https://www.instagram.com/notesfromlaw" rel="noopener noreferrer" target="_blank" onClick={() => handleLinkClick('instagram')}>
          <i className="icon fa fa-instagram" aria-hidden="true" />
          <span className={styles.srOnly}>Instagram</span>
        </a>
        {/* <a href="https://ko-fi.com/lawrenceluo" rel="noopener noreferrer" target="_blank"><i className="fa fa-gift" aria-hidden="true" /><span className="sr-only">Donate</span></a> */}
      </div>
    </div>
  )
}

const Footer = () => {
  return process.env.REACT_APP_WEBSITE_TYPE === "MUSIC" ? <MusicFooter /> : <TechFooter />
}

export default Footer;