import React, { useState } from 'react';
import { analytics } from '../../firebase';
import { logEvent } from 'firebase/analytics';

import { sendContactMessage } from '../../firebase';

import '../../css/App.module.css';
import styles from '../../css/Contact.module.css';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Basic validation
    if (!formData.name || !formData.email || !formData.message) {
      setError('Please fill out all required fields');
      return;
    }

    // Track form submission with analytics
    if (analytics) {
      logEvent(analytics, 'contact_form_submit', {
        subject: formData.subject || 'No subject'
      });
    }

    const result = await sendContactMessage(formData.name, formData.email, formData.message);
    if (result.success) {
      setSubmitted(true);
      setError('');
    } else {
      setError(result.message);
    }
  };

  if (submitted) {
    return (
      <div className="mainContainer textCenteredOnMobile">
        <div className={`${styles.contactContainer} ${styles.centeredHorizontally}`}>
          <h1>THANK YOU</h1>
          <p style={{ paddingTop: "0.5rem", paddingBottom: "1rem" }}>Your message has been sent. We'll be in touch soon!</p>
          <button
            className={styles.submitButton}
            onClick={() => {
              setSubmitted(false);
              setFormData({
                name: '',
                email: '',
                subject: '',
                message: ''
              });
            }}
          >
            Send Another Message
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="mainContainer textCenteredOnMobile">
      <div className={styles.contactContainer}>
        <h1>CONTACT ME</h1>
        <p>Send me a message and I'll get back to you within 7 days.</p>

        <form className={styles.contactForm} onSubmit={handleSubmit}>
          <div className={styles.formGroup}>
            <input
              type="text"
              id="name"
              name="name"
              placeholder="Name (required)"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </div>

          <div className={styles.formGroup}>
            <input
              type="email"
              id="email"
              name="email"
              placeholder="Email (required)"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>

          <div className={styles.formGroup}>
            <input
              type="text"
              id="subject"
              name="subject"
              placeholder="Subject"
              value={formData.subject}
              onChange={handleChange}
            />
          </div>

          <div className={styles.formGroup}>
            <textarea
              id="message"
              name="message"
              placeholder="Message (required)"
              value={formData.message}
              onChange={handleChange}
              rows="6"
              required
            />
          </div>

          <button type="submit" className={styles.submitButton}>
            Send Message
          </button>
        </form>

        {error && <p className={styles.errorMessage}>{error}</p>}
      </div>
    </div>
  );
};

export default Contact;