import { initializeApp, FirebaseError } from 'firebase/app';
import { addDoc, collection, getFirestore} from 'firebase/firestore';
import { getAnalytics, isSupported, setUserProperties } from 'firebase/analytics';
import firebaseConfig from './data/firebaseConfig';

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Firestore
const db = getFirestore(app);

// Validation helper functions
const validateEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

const validateMessage = (message) => {
  return message.length <= 2000 && message.length > 0;
};

const validateName = (name) => {
  return name.length <= 50 && name.length > 0;
};

const sendContactMessage = async (name, email, message) => {
    try {
        // Input validation
        if (!name || !email || !message) {
            throw new Error('Missing required fields');
        }

        if (!validateName(name)) {
            throw new Error('Name must be between 1 and 50 characters long');
        }

        if (!validateEmail(email)) {
            throw new Error('Invalid email format. Please enter a valid email address.');
        }

        if (!validateMessage(message)) {
            throw new Error('Message must be between 1 and 2000 characters long.');
        }

        // Add a new document with a generated id.
        await addDoc(collection(db, "contactMessages"), {
          to: [process.env.REACT_APP_EMAIL],
          message: {
            subject: `Firebase Message from ${name.trim()} (${email.trim()})`,
            text: message.trim(),
            html: `
                <h2>New Contact Form Submission</h2>
                <p><strong>Name:</strong> ${name.trim()}</p>
                <p><strong>Email:</strong> ${email.trim()}</p>
                <p><strong>Message:</strong> ${message.trim()}</p>
                <p><strong>Time:</strong> ${new Date().toLocaleString()}</p>
            `,
            timestamp: Date.now()
          }
        });
        return { success: true, message: 'Message sent successfully' };
    } catch (error) {
      if (error instanceof FirebaseError) {
        return { success: false, message: `An error occurred while sending the message. Please try again, or contact me via Instagram or email me at ${process.env.REACT_APP_EMAIL}.` };
      } else {
        return { success: false, message: error.message };
      }
    }
}

// Analytics
let analytics = null;

// Only initialize analytics if it's supported in the environment
const initAnalytics = async () => {
  if (await isSupported()) {
    analytics = getAnalytics(app);

    // Enable enhanced demographic data collection
    if (analytics) {
      // Set analytics collection enabled (this is on by default, but explicitly setting it)
      // This collects age, gender, interests when available
      // Do we actually need this?
      setUserProperties(analytics, {
        allow_personalized_ads: true, // This helps collect demographic data
        website_type: process.env.REACT_APP_WEBSITE_TYPE || 'UNKNOWN'
      });
    }
  }
};

initAnalytics();

export { analytics, sendContactMessage };

// Firebase Analytics automatically collects:
// - User location (country, region)
// - Device information (model, category, brand)
// - Age and gender demographics (when available)
// - Interests (when available)
//
// This data is available in the Firebase Analytics dashboard under "User" tab
// Note: Demographic data collection is subject to user consent and platform restrictions